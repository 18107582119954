import React from 'react';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';

const Root = styled(Box)`
  position: relative;
  text-align: center;
`;

const CtaText = styled(Text)`
  color: ${p => p.theme.grey};
`;

const SignUpCta = () => {
  return (
    <Root py={[8, 8, `100px`]}>
      <Container>
        <MaxWidth>
          <Heading rank="h2">
            Start your journey to becoming
            <br />
            a better football coach today
          </Heading>
          <Box my={[3, 3, 5]}>
            <CtaText>With plans to suit everyone, The&nbsp;Coaching&nbsp;Manual is the perfect resource for soccer coaches who want to improve their understanding of coaching and create a first-class soccer learning environment for their players.</CtaText>
          </Box>
          <Button as="a" href="https://app.thecoachingmanual.com/signup?plan_type=basic">Sign up for free</Button>
        </MaxWidth>
      </Container>
    </Root>
  );
};

export default SignUpCta;
