import styled from 'styled-components';
import { Box } from '@rebass/grid';

const MaxWidth = styled(Box)`
  max-width: 800px;
`;

MaxWidth.defaultProps = {
  mx: `auto`,
};

export default MaxWidth;
